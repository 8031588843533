import React from "react"
import Layout from "../components/layout/Layout";
import { Helmet } from "react-helmet";   
import PagesItems from '../components/PagesItems'; 

 
const Page = ({ location, pageContext }) => {
 
  return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageContext.title} | Exekutorský úřad Praha 9</title> 
        </Helmet>
        <Layout location={location} pageContext={pageContext}> 
          
              <div className="mx-auto w-full  relative z-30  ">
                  <div className="mx-auto max-w-6xl w-full p-8 lg:px-14   z-auto  relative">    
                      
                        <div className="relative    lg:pb-20 pb-20">
                          
                          <div dangerouslySetInnerHTML={{__html: pageContext.pageContent}} className="mt-6 prose max-w-6xl   text-gray-600 mx-auto pb-10"> 
                          
                            </div>
                          <div className=" prose prose-blue lg:prose-lg max-w-6xl text-gray-500 mx-auto pb-10"> 
                            <PagesItems pageContext={pageContext} /> 
                          
                            </div>
                        </div>
                       
                    </div>

                  </div>
        </Layout>
      </>

  )
}
 
export default Page